import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  styled,
} from '@material-ui/core'
import { Link } from 'gatsby'
import React, { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { FormInput } from '.'
import { ErrorMessage } from '../error-message'
import LocationPicker from '../location-picker'
import PageHeader from '../page-header'
import { useLazyAbnLookupQuery } from '../../services/api/endpoints/associations'

const StyledCheckBoxContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        fontSize: '0.7rem',
      },
    }
  }}
`

export type AssociationDetailsFormProps = {
  form: UseFormReturn<FormInput, object>
}

export const AssociationDetailsForm = ({
  form: {
    formState: { errors },
    register,
    reset,
    setValue,
    watch,
  },
}: AssociationDetailsFormProps) => {
  const abn = watch('abn')
  const address = watch('address')
  const gstRegistered = watch('gstRegistered')
  const agreedToTruth = watch('agreedToTruth', false)
  const agreedToPolicies = watch('agreedToPolicies', false)

  const [abnLookup, { data: abnLookupData, isLoading: abnLookupLoading }] =
    useLazyAbnLookupQuery()

  useEffect(() => {
    if (!abnLookupLoading && abnLookupData) {
      reset({
        name:
          (abnLookupData.data?.BusinessName &&
            abnLookupData.data?.BusinessName[0]) ||
          abnLookupData.data?.EntityName,
        gstRegistered: abnLookupData.data?.Gst !== null,
      })
    }
  }, [abnLookupData, abnLookupLoading])

  return (
    <>
      <PageHeader title="Association Details" />
      <Box paddingBottom={8}>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={8} md={4}>
            <TextField
              label="ABN number"
              variant="filled"
              size="small"
              fullWidth
              {...register('abn')}
              required
            />
          </Grid>
          <Grid item xs={12} sm={8} md={4}>
            <Button onClick={() => abnLookup(abn)}>Search</Button>
          </Grid>
        </Grid>
        {errors.abn && <ErrorMessage>{errors.abn.message}</ErrorMessage>}
      </Box>
      <Grid direction="column" container spacing={2}>
        {!abnLookupLoading && abnLookupData && (
          <>
            <Grid item xs={12} sm={8} md={4}>
              <TextField
                label="Association Name"
                variant="filled"
                size="small"
                fullWidth
                {...register('name')}
                required
              />
              {errors.name && (
                <ErrorMessage>{errors.name.message}</ErrorMessage>
              )}
            </Grid>
            <Grid item xs={12} sm={8} md={4}>
              <TextField
                label="GST registered"
                variant="filled"
                size="small"
                fullWidth
                {...register('gstRegistered')}
                required
                select
                value={gstRegistered}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextField>
              {errors.gstRegistered && (
                <ErrorMessage>{errors.gstRegistered.message}</ErrorMessage>
              )}
            </Grid>
            <Grid item xs={12} sm={8} md={4}>
              <LocationPicker
                setAddress={(newAddress: any) =>
                  setValue('address', newAddress)
                }
                defaultValue={address}
              />
              {errors.address && (
                <ErrorMessage>Address is invalid</ErrorMessage>
              )}
            </Grid>
            <Grid item xs={12} sm={8} md={4}>
              <StyledCheckBoxContainer
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreedToPolicies}
                      onChange={() =>
                        setValue('agreedToPolicies', !agreedToPolicies)
                      }
                    />
                  }
                  label=""
                />
                <span style={{ marginRight: '0.5rem', marginLeft: '-1rem' }}>
                  I agree to{' '}
                </span>
                <Link
                  href="https://tritagrugby.com/privacy-policy"
                  target="_blank"
                >
                  Tri Tag Rugby's Privacy Policy{' '}
                </Link>
                <span style={{ marginLeft: '0.5rem' }}>
                  {errors.agreedToPolicies && (
                    <ErrorMessage>
                      {errors.agreedToPolicies.message}
                    </ErrorMessage>
                  )}
                </span>
              </StyledCheckBoxContainer>
            </Grid>

            <Grid item xs={12} sm={8} md={4}>
              <StyledCheckBoxContainer
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreedToTruth}
                      onChange={() => setValue('agreedToTruth', !agreedToTruth)}
                    />
                  }
                  label=""
                />
                <span style={{ marginRight: '0.5rem', marginLeft: '-1rem' }}>
                  I declare that the information provided is true and correct
                  and I am authorised to provide this information
                </span>
                {errors.agreedToTruth && (
                  <ErrorMessage>{errors.agreedToTruth.message}</ErrorMessage>
                )}
              </StyledCheckBoxContainer>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}
